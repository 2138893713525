import React from "react";
import { Grid, Typography, Tooltip } from "@mui/material";
import fullFrontImageWEBP from "../images/WelcomePageImages/fullSizeImage.webp";
import fullFrontImagePNG from "../images/WelcomePageImages/fullSizeImage.png";
import lotusLogo from "../images/NavBar/logo3.png";
import { Link } from "react-router-dom";
import { useWidth } from "../WidthContext";
import NavBar from "../Components/NavBar";
import GoogleMaps from "../Components/GoogleMaps";
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";
import { OuterDiv, LandingPageDiv, FullSocialsGrid, FullStyledIconButtons, FullStyledFacebookIcon, FullStyledInstagramIcon, 
    FullStyledDownArrowIcon, FullTextGrid, FullTextContainer, FullLotusLogoGrid, FullWelcomeTypography, FullAuthenticTextGrid, FullAuthenticTextTypography, 
    FullMenuButtonGrid, FullLunchButton, FullDinnerButton, FullLotusFoodImageGrid, MedGridContainer, MedGridItem, MedLotusTypography, MedAuthenticTypography,
    MedButtonGrid, MedSocialIconGrids, MedLunchDinnerButtons, MedStyledIconButton, MedStyledFacebookIcon, MedStyledInstagramIcon, MedStyledDownArrowIcon,
    MobileGridContainer, MobileGridItem, MobileLotusTypography, MobileAuthenticTypography, MobileLunchDinnerGrid, MobileLunchDinnerButtons,
    MobileSocialIconGrids, MobileStyledIconButton, MobileStyledFacebookIcon, MobileStyledInstagramIcon, MobileStyledDownArrowIcon, AboutUsGridContainer,
    ParallaxGrids, ParallaxLunchDinnerDiv, ParallaxLunchDinnerButtons, MobileGrid, HoursGridItem, HoursSectionTypography, LunchDinnerGridItem,
    CallInformationGridItem, PhoneAnchor, DeliveryGridContainer, DeliveryGridItem, LogoGridItem, StyledAnchor, StyledImage, ImageSectionContainer, 
    TestimonialContainer, CarouselGridItem, CarouselPagePrevNextButton, CarouselPageButtons, StyledCircleIcon } from "./WelcomeStyles";
import LunchImageWebp from "../images/WelcomePageImages/LunchImage.webp";
import DinnerImageWebp from "../images/WelcomePageImages/DinnerImage.webp";
import LunchImagePNG from "../images/WelcomePageImages/LunchImage.png";
import DinnerImagePNG from "../images/WelcomePageImages/DinnerImage.jpg";
import UberEatsLogoWebp from "../images/WelcomePageImages/UberEatsLogo.webp";
import SkipLogoWebp from "../images/WelcomePageImages/SkipLogo.webp";
import UberEatsLogoPNG from "../images/WelcomePageImages/UberEatsLogo.png";
import SkipLogoPNG from "../images/WelcomePageImages/SkipLogo.png";
import Image1Webp from "../images/WelcomePageImages/Image1.webp";
import Image2Webp from "../images/WelcomePageImages/Image2.webp";
import Image3Webp from "../images/WelcomePageImages/Image3.webp";
import Image4Webp from "../images/WelcomePageImages/Image4.webp";
import Image1PNG from "../images/WelcomePageImages/Image1.png";
import Image2PNG from "../images/WelcomePageImages/Image2.png";
import Image3PNG from "../images/WelcomePageImages/Image3.png";
import Image4PNG from "../images/WelcomePageImages/Image4.png";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Footer from "../Components/Footer";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const NewWelcome = () => {
    const { width } = useWidth();
    
    // Pagination variable
    const [testimonialPage, setTestimonialPage] = React.useState(1);

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <OuterDiv>
            
            {/* Landing Screen Section */}
            <LandingPageDiv>
                {/* Large Screen Devices*/}
                {width > 1000 ? 
                    <Grid container>
                        <Grid item xs={0.5} />

                        <FullSocialsGrid item xs={0.5}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FullStyledIconButtons animationdirection="up" href="https://www.facebook.com/lotusthainviet/" target="_blank">
                                        <FullStyledFacebookIcon width={width} />
                                    </FullStyledIconButtons>
                                </Grid>
                                <Grid item xs={12}>
                                    <FullStyledIconButtons animationdirection="up" href="https://www.instagram.com/lotus_cuisine/" target="_blank">
                                        <FullStyledInstagramIcon width={width} />
                                    </FullStyledIconButtons>
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                    <FullStyledIconButtons animationdirection="down" href="#About">
                                        <FullStyledDownArrowIcon width={width} />
                                    </FullStyledIconButtons>
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                    <br />
                                    <br />
                                </Grid>
                            </Grid>
                        </FullSocialsGrid>

                        <FullTextGrid item xs={4.5}>
                            <FullTextContainer container>
                                <FullLotusLogoGrid item xs={12}>
                                    <Typography align="center">
                                        <img src={lotusLogo} alt="LotusLogo" height={width > 1600 ? "90%" : "80%"} width={width > 1600 ? "90%" : width > 1400 ? "80%" : width > 1300 ? "70%" : width > 1100 ? "60%" : "50%"} />
                                    </Typography>
                                </FullLotusLogoGrid>

                                <Grid item xs={12}>
                                    <FullWelcomeTypography align="center" variant={width > 1600 ? "h3" : width > 1300 ? "h4" : "h5"}>Welcome to Lotus</FullWelcomeTypography>
                                </Grid>

                                <FullAuthenticTextGrid item xs={12}>
                                    <FullAuthenticTextTypography align="center" variant={width > 1600 ? "h6" : width > 1300 ? "subtitle1" : "subtitle2"}>Authentic Thai & Vietnamese Cuisine</FullAuthenticTextTypography>
                                    <br />
                                    <br />
                                </FullAuthenticTextGrid>

                                <FullMenuButtonGrid item xs={12} width={width}>
                                    <FullLunchButton width={width} variant="outlined" component={Link} to="/Lunch">View Lunch Specials</FullLunchButton>
                                    &nbsp;&nbsp;&nbsp;
                                    {width > 1600 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                    {width > 1300 && <>&nbsp;&nbsp;&nbsp;</>}
                                    <FullDinnerButton width={width} component={Link} to="/Dinner" variant="contained">View Dinner Menu</FullDinnerButton>
                                </FullMenuButtonGrid>
                            </FullTextContainer>
                        </FullTextGrid>

                        <FullLotusFoodImageGrid item xs={6.5}>
                            <picture>
                                <source srcSet={fullFrontImageWEBP} type="image/webp" />
                                <source srcSet={fullFrontImagePNG} type="image/png" />
                                <img src={fullFrontImagePNG} alt="LotusImages" height="auto" width="90%" />
                            </picture>
                        </FullLotusFoodImageGrid>
                    </Grid>

                // Medium Screen Devices
                : width > 600 ?
                    <MedGridContainer container>
                        <MedGridItem item xs={12}>
                            <MedLotusTypography align="center" variant="h4">LOTUS</MedLotusTypography>

                            <MedAuthenticTypography align="center" variant="h6">Authentic Thai & Vietnamese Cuisine</MedAuthenticTypography>
                            <br />
                            <br />

                            <Grid container>
                                <MedButtonGrid item xs={12}>
                                    <MedLunchDinnerButtons variant="outlined" component={Link} to="/Lunch">View Lunch Specials</MedLunchDinnerButtons>
                                    &nbsp;&nbsp;&nbsp;
                                    <MedLunchDinnerButtons variant="outlined" component={Link} to="/Dinner">View Dinner Menu</MedLunchDinnerButtons>
                                </MedButtonGrid>

                                <MedSocialIconGrids item xs={12} top="78vh">
                                    <MedStyledIconButton animationdirection="up" href="https://www.facebook.com/lotusthainviet/" target="_blank">
                                        <MedStyledFacebookIcon />
                                    </MedStyledIconButton>
                                </MedSocialIconGrids>

                                <MedSocialIconGrids item xs={12} top="84vh">
                                    <MedStyledIconButton animationdirection="up" href="https://www.instagram.com/lotus_cuisine/" target="_blank">
                                        <MedStyledInstagramIcon />
                                    </MedStyledIconButton>
                                </MedSocialIconGrids>

                                <MedSocialIconGrids item xs={12} top="92vh">
                                    <MedStyledIconButton animationdirection="down" href="#About">
                                        <MedStyledDownArrowIcon />
                                    </MedStyledIconButton>
                                </MedSocialIconGrids>
                            </Grid>
                        </MedGridItem>
                    </MedGridContainer>

                // Mobile Devices
                :
                    <MobileGridContainer container>
                        <MobileGridItem width={width} item xs={12}>
                            <MobileLotusTypography align="center" variant="h4">LOTUS</MobileLotusTypography>

                            <MobileAuthenticTypography align="center" variant="h6">Authentic Thai & Vietnamese Cuisine</MobileAuthenticTypography>
                            <br />
                            <br />

                            <Grid container>
                                <MobileLunchDinnerGrid item xs={12}>
                                    <MobileLunchDinnerButtons variant="outlined" component={Link} to="/Lunch">View Lunch Specials</MobileLunchDinnerButtons>
                                    &nbsp;&nbsp;&nbsp;
                                    <MedLunchDinnerButtons variant="outlined" component={Link} to="/Dinner">View Dinner Menu</MedLunchDinnerButtons>
                                </MobileLunchDinnerGrid>

                                <MobileSocialIconGrids item xs={12} top="92vh" left={width > 500 ? "88vw" : "85vw"}>
                                    <MobileStyledIconButton href="#About" animationdirection="down">
                                        <MobileStyledDownArrowIcon />
                                    </MobileStyledIconButton>
                                </MobileSocialIconGrids>

                                <MobileSocialIconGrids item xs={12} top="86vh" left="5vw">
                                    <MobileStyledIconButton href="https://www.facebook.com/lotusthainviet/" target="_blank" animationdirection="up">
                                        <MobileStyledFacebookIcon />
                                    </MobileStyledIconButton>
                                </MobileSocialIconGrids>

                                <MobileSocialIconGrids item xs={12} top="92vh" left="5vw">
                                    <MobileStyledIconButton href="https://www.instagram.com/lotus_cuisine/" target="_blank" animationdirection="up">
                                        <MobileStyledInstagramIcon />
                                    </MobileStyledIconButton>
                                </MobileSocialIconGrids>
                            </Grid>

                        </MobileGridItem>
                    </MobileGridContainer>
                }
            </LandingPageDiv>



            {/* About Us Section */}
            <AboutUsGridContainer container id="About" width={width}>
                <Grid item xs={12}>
                    <div>
                        <Typography variant={width > 1200 ? "h6" : width > 900 ? "subtitle1" : "subtitle2"} align="center" sx={{ fontFamily: "Source Sans Pro", fontWeight: "bold", marginTop: width > 1200 ? "0%" : width > 900 ? "7%" : width > 400 ? "9%" : "10%" }}>
                            <u style={{ textUnderlineOffset: "10px", textDecorationThickness: "2px" }}>
                                ABOUT US
                            </u>
                        </Typography>
                        <Typography variant="subtitle1" align="center" style={{ marginTop: width > 1200 ? "1%" : width > 900 ? "2%" : width > 600 ? "3%" : width > 400 ? "4%" : "5%", marginBottom: width > 1200 ? "0%" : width > 900 ? "7%" : "10%", fontFamily: "Source Sans Pro", fontSize: width > 1000 ? "18px" : "14px" }}>
                            Lotus opened its doors in 2015 and have been
                            serving authentic Thai & Vietnamese cuisine ever
                            since. We are a family owned business with roots
                            from Vietnam, learning many aspects of
                            Vietnamese cuisine through our parents. Backed
                            by a Thai chef with more than 25 years of
                            experience, he taught various chefs in Ontario
                            and mentored us on the different characteristics
                            of Thai cuisine. Our knowledge and love for both
                            cuisines has driven us to serve a menu that
                            supports both Thai & Vietnamese food, ensuring
                            we respect each cuisines special traditions
                            while focusing on serving high quality food.
                            Thank you to all those that have supported us
                            over the years, giving us the opportunity to
                            continue feeding our customers with the food we
                            love. We are looking forward to seeing you soon!
                        </Typography>
                    </div>
                </Grid>
            </AboutUsGridContainer>



            {/* View Lunch and Dinner Image Section */}
            {width > 1000 ? 
                <div>
                    <ParallaxProvider>
                        <Grid container>
                            <ParallaxGrids item xs={6} paddingRight={width > 1500 ? "0.5%" : ""}>
                                <ParallaxBanner layers={[{ children: (<ParallaxLunchComponent />), amount: 0 }, { children: (<ParallaxLunchComponent />), amount: 0.5 }]} style={{ height: "50vh" }} />
                                <ParallaxLunchDinnerDiv>
                                    <ParallaxLunchDinnerButtons component={Link} to="/Lunch">View Lunch Specials</ParallaxLunchDinnerButtons>
                                </ParallaxLunchDinnerDiv>
                            </ParallaxGrids>

                            <ParallaxGrids item xs={6} paddingleft={width > 1500 ? "0.5%" : ""}>
                                <ParallaxBanner layers={[{ children: (<ParallaxDinnerComponent />), amount: 0 }, { children: (<ParallaxDinnerComponent />), amount: 0.5}]} style={{ height: "50vh" }} />
                                <ParallaxLunchDinnerDiv>
                                    <ParallaxLunchDinnerButtons component={Link} to="/Dinner">View Dinner Menu</ParallaxLunchDinnerButtons>
                                </ParallaxLunchDinnerDiv>
                            </ParallaxGrids>
                        </Grid>
                    </ParallaxProvider>
                </div>
            :
                <Grid container>
                    <MobileGrid item xs={6}>
                        <div>
                            <picture>
                                <source srcSet={LunchImageWebp} type="image/webp" />
                                <source srcSet={LunchImagePNG} type="image/png" />
                                <img src={LunchImagePNG} alt="LunchImage" height="auto" width="100%" />
                            </picture>
                        </div>
                        <ParallaxLunchDinnerDiv>
                            <ParallaxLunchDinnerButtons component={Link} to="/Lunch">View Lunch Specials</ParallaxLunchDinnerButtons>
                        </ParallaxLunchDinnerDiv>
                    </MobileGrid>

                    <MobileGrid item xs={6}>
                        <div>
                            <picture>
                                <source srcSet={DinnerImageWebp} type="image/webp" />
                                <source srcSet={DinnerImagePNG} type="image/png" />
                                <img src={DinnerImagePNG} alt="DinnerImage" height="auto" width="100%" />
                            </picture>
                        </div>
                        <ParallaxLunchDinnerDiv>
                            <ParallaxLunchDinnerButtons component={Link} to="/Dinner">View Dinner Menu</ParallaxLunchDinnerButtons>
                        </ParallaxLunchDinnerDiv>
                    </MobileGrid>
                </Grid>
            }



            {/* Hours Section */}
            <Grid container>
                <HoursGridItem item xs={12} width={width}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">
                        <u style={{ textUnderlineOffset: "4px", textDecorationThickness: "2px"}}>
                            <b>Hours</b>
                        </u>
                    </HoursSectionTypography>
                </HoursGridItem>
                <Grid item xs={12}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">Mon: CLOSED</HoursSectionTypography>
                </Grid>
                <Grid item xs={12}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">Tue - Fri: 11AM - 8PM</HoursSectionTypography>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">Sat - Sun: 12PM - 8PM</HoursSectionTypography>
                </Grid>

                <LunchDinnerGridItem item xs={12} width={width}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center"><b>Lunch</b></HoursSectionTypography>
                </LunchDinnerGridItem>
                <Grid item xs={12}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">Tue - Fri: 11AM - 3PM</HoursSectionTypography>
                </Grid>

                <LunchDinnerGridItem item xs={12} width={width}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center"><b>Dinner</b></HoursSectionTypography>
                </LunchDinnerGridItem>
                <Grid item xs={12}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">Tue - Fri: 3PM - 8PM</HoursSectionTypography>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">Sat - Sun: 12PM - 8PM</HoursSectionTypography>
                </Grid>
            </Grid>



            {/* Address and Phone Section */}
            <Grid container>
                <CallInformationGridItem item xs={12} width={width}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center"><b>Call to place an order for pick-up</b></HoursSectionTypography>
                </CallInformationGridItem>
                <Grid item xs={12}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">280 Mud St W, Stoney Creek, ON L8J 3Z6</HoursSectionTypography>
                </Grid>
                <Grid item xs={12}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">
                        <PhoneAnchor href="tel:905-561-1888">
                            (905) - 561 - 1888
                        </PhoneAnchor>
                    </HoursSectionTypography>
                </Grid>
            </Grid>



            {/* Delivery Logo Section */}
            <DeliveryGridContainer container>
                <DeliveryGridItem item xs={12} width={width}>
                    <HoursSectionTypography variant={width > 1000 ? "h6" : "subtitle1"} align="center">
                        <b>Delivery Options</b>
                    </HoursSectionTypography>
                </DeliveryGridItem>

                <LogoGridItem item xs={width > 1000 ? 0.9 : width > 700 ? 1.5 : 2} width={width}>
                    <LogoComponent url="https://www.skipthedishes.com/lotus-thai-and-vietnamese-cuisine" LogoSrcWebp={SkipLogoWebp} logoSrcPNG={SkipLogoPNG} logoTitle="SkipTheDishesLogo" logoName="Skip The Dishes" />
                </LogoGridItem>

                <LogoGridItem item xs={width > 1000 ? 0.9 : width > 700 ? 1.5 : 2} width={width}>
                    <LogoComponent url="https://www.ubereats.com/ca/store/lotus-thai-%26-vietnamese-cuisine/pLHy7100Xle1JFmvGPBp4Q?diningMode=DELIVERY" LogoSrcWebp={UberEatsLogoWebp} logoSrcPNG={UberEatsLogoPNG} logoTitle="UberEatsLogo" logoName="Uber Eats" />
                </LogoGridItem>
            </DeliveryGridContainer>

            

            {/* Image Section */}
            <ImageSectionContainer container width={width}>
                <Grid item xs={width > 700 ? 3 : 6} sx={{position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 319</Typography>
                    </div>
                    <FullScreenPictureComponent mobile={width <= 700 ? "true" : "false"} image1SourceWebp={Image1Webp} image1SourcePNG={Image1PNG} styledImage={true} />
                </Grid>
                <Grid item xs={width > 700 ? 3 : 6} sx={{position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 103</Typography>
                    </div>
                    <FullScreenPictureComponent mobile={width <= 700 ? "true" : "false"} image1SourceWebp={Image2Webp} image1SourcePNG={Image2PNG} styledImage={true} />
                </Grid>
                <Grid item xs={width > 700 ? 3 : 6} sx={{position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 402</Typography>
                    </div>
                    <FullScreenPictureComponent mobile={width <= 700 ? "true" : "false"} image1SourceWebp={Image3Webp} image1SourcePNG={Image3PNG} styledImage={true} />
                </Grid>
                <Grid item xs={width > 700 ? 3 : 6} sx={{position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 508</Typography>
                    </div>
                    <FullScreenPictureComponent mobile={width <= 700 ? "true" : "false"} image1SourceWebp={Image4Webp} image1SourcePNG={Image4PNG} styledImage={true} />
                </Grid>
            </ImageSectionContainer>



            {/* Testimonial Section */}
            <TestimonialContainer width={width}>
                <Grid item xs={12}>
                    <Typography variant={width > 1000 ? "h6" : "subtitle1"} align="center" sx={{fontFamily: "Montserrat", fontWeight: 600}}>
                        <u style={{ textUnderlineOffset: "4px", textDecorationThickness: "2px"}}>
                            Customer Reviews
                        </u>
                    </Typography>
                    <br />
                    <br />
                    <div style={{display: "flex", justifyContent: "center"}}>
                        {[...Array(testimonials[testimonialPage - 1].stars)].map((instance, ID) => (
                            <StarIcon key={ID} sx={{color: "#E7B22C"}} />
                        ))}
                        {testimonials[testimonialPage - 1].halfStar === true &&
                            <StarHalfIcon sx={{color: "#E7B22C"}} />
                        }
                    </div>
                    <br />
                    <Typography variant="subtitle1" align="center" sx={{ fontFamily: "Source Sans Pro", fontSize: width > 1000 ? "18px" : "14px" }}>
                        <FormatQuoteIcon style={{ transform: "scaleX(-1)", fontSize: "15px" }} />
                        {testimonials[testimonialPage - 1].description}
                        <FormatQuoteIcon style={{ fontSize: "15px" }} />
                        <br /> <b>-</b>
                        &nbsp;{testimonials[testimonialPage - 1].name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <CarouselGridItem>
                    <CarouselPagePrevNextButton disabled={testimonialPage === 1 ? true : false} onClick={() => setTestimonialPage(testimonialPage - 1)}><ArrowBackIcon /></CarouselPagePrevNextButton>
                    <CarouselPageButtons testimonialpage={testimonialPage} testimonialbuttonnumber={1} onClick={() => {setTestimonialPage(1)}}><StyledCircleIcon /></CarouselPageButtons>
                    <CarouselPageButtons testimonialpage={testimonialPage} testimonialbuttonnumber={2} onClick={() => {setTestimonialPage(2)}}><StyledCircleIcon /></CarouselPageButtons>
                    <CarouselPageButtons testimonialpage={testimonialPage} testimonialbuttonnumber={3} onClick={() => {setTestimonialPage(3)}}><StyledCircleIcon /></CarouselPageButtons>
                    <CarouselPageButtons testimonialpage={testimonialPage} testimonialbuttonnumber={4} onClick={() => {setTestimonialPage(4)}}><StyledCircleIcon /></CarouselPageButtons>
                    <CarouselPagePrevNextButton disabled={testimonialPage === 4 ? true : false} onClick={() => setTestimonialPage(testimonialPage + 1)}><ArrowForwardIcon /></CarouselPagePrevNextButton>
                </CarouselGridItem>
            </TestimonialContainer>



            {/* Google Map Section */}
            <GoogleMaps />



            {/* Footer */}
            <Footer />

            {/* NavBar */}
            <NavBar />
        </OuterDiv>
    )
}

export default NewWelcome;


const FullScreenPictureComponent = (props) => (
    <div style={{ height: "100%", width: "100%" }}>
        <picture>
            <source srcSet={props.image1SourceWebp} type="image/webp" />
            <source srcSet={props.image1SourcePNG} type="image/png" />
            <img
                alt={props.pictureTitle}
                src={props.image1SourcePNG}
                height="100%"
                width="100%"
                mobile={props.mobile}
            />
        </picture>
    </div>
);

const LogoComponent = (props) => (
    <Tooltip placement="top" title={props.logoName} componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
        <StyledAnchor target="_blank" href={props.url} rel="noreferrer">
            <div>
                <picture>
                    <source srcSet={props.LogoSrcWebp} type="image/webp" />
                    <source srcSet={props.LogoSrcPNG} type="image/png" />
                    <StyledImage
                        alt={props.logoTitle}
                        src={props.LogoSrcPNG}
                        height="auto"
                        width="100%"
                    />
                </picture>
            </div>
        </StyledAnchor>
    </Tooltip>
);

const ParallaxLunchComponent = () => (
    <div>
        <picture>
            <source srcSet={LunchImageWebp} type="image/webp" />
            <source srcSet={LunchImagePNG} type="image/png" />
            <img src={LunchImagePNG} alt="LunchImage" style={{ height: "100%", width: "100%" }} />
        </picture>
    </div>
);

const ParallaxDinnerComponent = () => (
    <div>
        <picture>
            <source srcSet={DinnerImageWebp} type="image/webp" />
            <source srcSet={DinnerImagePNG} type="image/png" />
            <img src={DinnerImagePNG} alt="DinnerImage" style={{ height: "100%", width: "100%" }} />
        </picture>
    </div>
);

// Testimonial Array
const testimonials = [
    { description: "Amazing pad Thai! Food was extremely good, great pricing and portions and wonderful customer service, will be back many times finally happy to find a great Thai place in the area.", name: "Natalia T.", stars: 5, halfStar: false },
    { description: "We ordered for a group of people a few weeks back. We were unsure what to order they helped us with the best dishes and were so patient. It was so delicious and perfect amount. Thank you", name: "Nicole V.", stars: 5, halfStar: false },
    { description: "The food and the service here we're both phenomenal! The owner is unbeliavbly kind and very accomdating to any order changes. And the food is spot on!", name: "Fatima", stars: 5, halfStar: false },
    { description: "Everything was tasty and fresh. The spice on everything was just right and will definitely come back. My new favorite Thai place in Hamilton area.", name: "Kiran K.", stars: 4, halfStar: true }
]