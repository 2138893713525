import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContextProvider from "./WidthContext";
import Welcome from "./WelcomePage/Welcome";
import LunchPage from "./LunchPage/LunchPage";
import DinnerPage from "./DinnerPage/DinnerPage";
import ContactPage from "./ContactPage/ContactPage";
import GalleryPage from "./GalleryPage/GalleryPage";
import NotFound from "./404/NotFound";

const App = () => {
    return (
        <Router>
            <ContextProvider>
                <Routes>
                    <Route exact path="/" element={<Welcome />} />
                    <Route path="/Lunch" element={<LunchPage />} />
                    <Route path="/Dinner" element={<DinnerPage />} />
                    <Route path="/Contact" element={<ContactPage />} />
                    <Route path="/Gallery" element={<GalleryPage />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </ContextProvider>
        </Router>
    );
};

export default App;
